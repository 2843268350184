import Title from '../parts/Title';
import DownloadPDFButton from '../parts/DownloadPDFButton';

function Brochures({data}){
    return(
        <>
            <Title title="Brochures"/>
            <div className='uk-flex uk-flex-wrap gpa_grid gpa_col_7'>
                {data.map(a =>
                    <DownloadPDFButton data={a} text={'PDF'}/>
                )}
            </div>
        </>
    );
}

export default Brochures;