import React from 'react';

const DownloadButton = ({data}) => {
    return (
        <a href={data.href} className="gpa_download_pdf uk-position-relative" download={data.download}>
            <div className='gpa_download_pdf_text'>
                {data.lang}
            </div>
        </a>
    );
};

export default DownloadButton;