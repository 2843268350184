import Title from '../parts/Title';
import DownloadPDFButton from '../parts/DownloadPDFButton';
import DownloadButton from '../parts/DownloadButton';
import '../scss/successCase.scss';

function SuccessCase({data}){
    return(
        <>
            <Title title="Presentation"/>
            <div className='gpa_sc uk-flex uk-flex-wrap gpa_grid gpa_col_7'>
                {data[0].map(a=>
                    <DownloadPDFButton data={a} text={'PDF'}/>
                )}
            </div>
            <Title title="Success cases"/>
            <div className='gpa_sc uk-flex uk-flex-wrap gpa_grid gpa_col_7'>
                {data[1].map(a =>
                    <>
                        <a href={'#sc_'+ a.id +'modal'} className='gpa_sc_anchor uk-position-relative' uk-toggle="mode:click">
                            <div className="gpa_sc_anchor_banner uk-width-1-1 uk-padding-small uk-position-relative uk-flex uk-flex-wrap uk-flex-center uk-flex-middle ">
                                <img alt={a.title} src={a.thumb}/>
                                <p className="uk-width-1-1">{a.title}</p>
                            </div>
                            <div className="uk-width-1-1 gpa_sc_anchor_cover uk-position-absolute uk-flex uk-flex-wrap uk-flex-center uk-flex-middle">
                                <div className="gpa_sc_anchor_cover_icon">
                                    <img alt="Icon" src="/img/icons/gpa-icon-download.svg" uk-svg="stroke-animation:false"/>
                                </div>
                                <span>PDF</span>
                            </div>
                        </a>
                        <div id={'sc_'+ a.id +'modal'} className="uk-flex-top gpa_sc_modal" uk-modal="container:true">
                            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                                <button className="uk-modal-close-default" type="button" uk-close=""></button>
                                <div className="gpa_modal_body gpa_grid gpa_col_2">
                                    <div className="gpa_sc_preview_cover">
                                        <img className="" alt={a.title} src={a.thumb} width={400}/>
                                        <p className="uk-width-1-1">{a.title}</p>
                                    </div>
                                    <div className="gpa_sc_download_buttons uk-flex uk-flex-column uk-flex-between">
                                        {a.children.map(b =>
                                            <DownloadButton data={b}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default SuccessCase;