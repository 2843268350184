import React from 'react';
import '../scss/downloadPDFButton.scss';

const DownloadPDFButton = ({data, text}) => {
    const downloadPDF = (b) => {
        const url = b.href; // Ruta al archivo PDF en la carpeta public
        const a = document.createElement('a');
        a.href = url;
        a.download = b.download;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div className="gpa_download_pdf uk-position-relative" onClick={() => downloadPDF(data)}>
            <div className="gpa_download_pdf_image uk-flex uk-flex-wrap uk-flex-center uk-flex-middle">
                <img className="gpa_download_pdf_preview gpa_height_250" alt={data.title} src={data.thumb}/>
                {data.title ?
                    <p className="gpa_download_pdf_title">{data.title}</p>
                : ''}
            </div>
            <div className="gpa_download_pdf_preview_mask uk-padding-small uk-position-absolute uk-flex uk-flex-wrap uk-flex-center uk-flex-middle">
                <img className="gpa_download_pdf_icon" alt="icon" src="/img/icons/gpa-icon-download.svg" uk-svg="stroke-animation:false"/>
                <span className="gpa_download_pdf_icon_text">{text}</span>
            </div>
        </div>
    );
};

export default DownloadPDFButton;