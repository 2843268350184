import './scss/Main.scss';
// import Nav from './Nav.js';
import { Outlet } from "react-router-dom";

function Main() {
  return (
    <>
        <Outlet/>
    </>
  );
}

export default Main;
