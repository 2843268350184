import Title from '../parts/Title';
import DownloadPDFButton from '../parts/DownloadPDFButton';
import '../scss/backgrounds.scss';

function Background({data}){
    return(
        <>
            <Title title="Backgrounds"/>
            <div className='uk-flex uk-flex-wrap gpa_grid gpa_col_6'>
                {data[0].map(a =>
                    <div uk-lightbox="autoplay:false">
                        <a className="uk-button uk-button-default" href={`/img/backgrounds/${a.url}`}>
                            <img src={`/img/thumb/backgrounds/${a.thumb}`} alt=""/>
                        </a>
                    </div>
                )}
                {data[1].map(a =>
                    <DownloadPDFButton data={a} text={'JPG'}/>
                )}
            </div>
        </>
    );
}

export default Background;