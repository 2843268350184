import Title from '../parts/Title';

function Logos(){
    return(
        <>
            <Title title="Logos"/>
        </>
    );
}

export default Logos;