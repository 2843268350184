import Title from "../parts/Title";
import "../scss/parts.scss";

function Piece({data}){
    return(
        <>
            <Title title="Parts"/>
            <div className="gpa_switcher uk-width-expand uk-grid-collapse">
                <ul className="gpa_switcher_nav uk-subnav uk-subnav-pill uk-width-1-1" uk-switcher="active:0">
                    {data.map(a => <li><button>{a.title}</button></li>)}
                </ul>
                <ul className="gpa_switcher_items uk-switcher switcher-container uk-margin uk-width-1-1">
                    {data.map(a =>
                        <li>
                            <div className="gpa_switcher_cont gpa_grid gpa_col_6">
                                {a.children.map(b =>
                                    <div className="gpa_switcher_cont_item">
                                        <a href={`/img/pieces_polishing/${a.title}/${b.name}.${b.ext}`} className="uk-position-relative" download={`${b.name}.${b.ext}`}>
                                            <div className="uk-width-1-1 uk-text-center uk-padding-small">
                                                <img className="uk-height-small" alt="" src={`${process.env.PUBLIC_URL}/img/thumb/pieces_polishing/${a.title}/${b.name}.webp`}/>
								            </div>
								            <div className="uk-width-1-1 gpa_switcher_cont_item_cover uk-position-absolute uk-flex uk-flex-wrap uk-flex-center uk-flex-middle">
									            <div className="gpa-icon">
										            <img className="img-logos" alt="" src={`${process.env.PUBLIC_URL}/img/icons/gpa-icon-download.svg`} uk-svg="stroke-animation:false"/>
									            </div>
									            <span className="gpa-text">JPG</span>
								            </div>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
}

export default Piece;