import React, { useState } from 'react';

function SpanBox({data}){
    const [isActive, setIsActive] = useState(false);
    const handleClick = (a) => {
        setIsActive(!isActive);
        navigator.clipboard.writeText(a);
    };

    return(
        <>
            <span className={isActive ? 'gpa_video_item_icon_copied' : 'gpa_video_item_icon_copy'} onClick={() => handleClick(data[1])}>
                <img src={process.env.PUBLIC_URL + data[2]} alt="icon" width={16} height={16} uk-svg="stroke-animation:false"/> {data[0]}
            </span>
        </>
    );
}
export default SpanBox;