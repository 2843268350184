import React from 'react';
import DOMPurify from 'dompurify';

const HtmlRenderer = ({ htmlString, className }) => {
    const cleanHtmlString = DOMPurify.sanitize(htmlString);
    return (
        <p className={className} dangerouslySetInnerHTML={{ __html: cleanHtmlString }} />
    );
};

export default HtmlRenderer;