import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './Main';
import Video from './pages/Video';
import Brochure from './pages/Brochure';
import Technical from './pages/Technical';
import SuccessCase from './pages/SuccessCase';
import Piece from './pages/Parts';
import Logos from './pages/Logos';
import Background from './pages/Background';
import './scss/index.scss';
import data from './data.json';
import './scss/Nav.scss';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main/>}>
        <Route index element={<Video data={data.videos}/>}/>
        <Route path="videos/*" element={<Video data={data.videos}/>}/>
        <Route path="brochures/*" element={<Brochure data={data.brochures}/>}/>
        <Route path="technical_data/*" element={<Technical data={data.pdfs}/>}/>
        <Route path="success_cases/*" element={<SuccessCase data={[data.sc_presentation,data.sc_pdfs]}/>}/>
        <Route path="parts/*" element={<Piece data={data.pieces}/>}/>
        <Route path="logos/*" element={<Logos/>}/>
        <Route path="gpa_backgrounds/*" element={<Background data={[data.backgrounds, data.banners]}/>}/>
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
