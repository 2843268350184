import '../scss/Videos.scss';
import Title from '../parts/Title';
import Item from '../parts/Item';

function Video({data}){
    return (
        <>
            <Title title="Videos"/>
            {data.map(a =>
                <div key={a.id} id={'videos-' + a.id} className="gpa_video">
                    <Title title={a.title}/>
                    <div className={'gpa_video_grid gpa_grid gpa_col_' + a.grid}>
                        {Object.keys(a).includes('children') ? <Item children={a.children}/> : ''}
                    </div>
                </div>
            )}
        </>
    );
}
export default Video;