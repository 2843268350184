import  { ReactComponent as IconPlay } from '../img/icons/gpa-icon-play.svg';
import SpanBox from './SpanBox';
import HtmlRenderer from '../HtmlRenderer';

function Item({children}){
    return(
        <>
            {children.map((b, i) =>
                <div key={i} className="gpa_video_item" uk-lightbox="video-autoplay:true">
                    <a href={b.local ?  process.env.PUBLIC_URL + b.local[1] : process.env.PUBLIC_URL + b.href[1]} className='gpa_video_item_anchor'>
                        <div className="gpa_video_preview uk-flex uk-position-relative">
                            <img src={process.env.PUBLIC_URL +'/img/thumb/video/' + b.thumb} alt=""/>
                            <div className='gpa_video_layer_black uk-position-center'></div>
                            <HtmlRenderer htmlString={b.title} className="gpa_video_item_title uk-margin-remove uk-position-bottom-left"/>
                            <div className="gpa_video_preview_cover uk-width-1-1 uk-padding-small uk-position-relative uk-flex uk-flex-wrap uk-flex-center uk-flex-middle">
                                <div className="gpa_video_icon_play">
                                    <IconPlay width="50" height="51"/>
                                </div>
                            </div>
                        </div>
                    </a>
                    <div className="gpa_video_item_copy_url">
                        {b.local ? <SpanBox data={b.local}/> : ''} {b.href ? <SpanBox data={b.href}/> : ''}
                    </div>
                </div>
            )}
        </>
    );
}

export default Item;